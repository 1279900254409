import axios from "axios";

import config from "config/config";
import { getAuthToken } from "utils/helpers";
import { postRefreshToken } from "api/auth";

const BASE_URL = config.apiBaseUrl;

const isTokenExpired = (token) => {
  if (!token) return true;

  const [, payloadBase64] = token.split(".");
  const payload = JSON.parse(atob(payloadBase64));
  const expiryTime = payload.exp * 1000;
  const currentTime = Date.now();

  return currentTime > expiryTime;
};

/* Adjust authorization according to API */
const Client = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${getAuthToken()}`,
  },
});

axios.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 403 &&
      !originalRequest._retry
    ) {
      const refreshToken = localStorage.getItem("refreshToken");
      if (!refreshToken || isTokenExpired(refreshToken)) {
        localStorage.clear();
        return (window.location.href = "/");
      }
      try {
        const { data } = await postRefreshToken(refreshToken);

        const newRefreshToken = data.data.token;
        // localStorage.setItem('accessToken', accessToken);
        localStorage.setItem("refreshToken", newRefreshToken);

        originalRequest.headers["Authorization"] = `Bearer ${getAuthToken()}`;
        return Client(originalRequest);
      } catch (error) {
        console.log(error);
        localStorage.clear();
        return (window.location.href = "/");
      }
    }

    return Promise.reject(error);
  }
);

export const getRequest = (path, query, responseType = "json") => {
  return Client.get(path, {
    params: query,
    responseType: responseType,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const postRequestMultiPart = (path, data) => {
  return Client.post(path, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const postRequest = (path, data) => {
  return Client.post(path, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const patchRequest = (path, data) => {
  return Client.patch(path, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const multiGetRequest = async (paths) => {
  let arr = [];

  paths.forEach((item) => {
    arr.push(Client.get(`${BASE_URL}${item}`, {}));
  });
  return axios.all(arr);
};

export const multiPostRequest = async (configs) => {
  let arr = [];
  configs.forEach((item) => {
    arr.push(Client.post(`${BASE_URL}${item.url}`, item.data, {}));
  });
  return axios.all(arr);
};

export const putRequest = (path, data) => {
  return Client.put(path, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const headRequest = (path) => {
  return Client.head(path, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const deleteRequest = (path, params) => {
  return Client.delete(path, {
    headers: {
      "Content-Type": "application/json",
    },
    params: params,
  });
};
