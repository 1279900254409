import React from "react";

import { GoogleLogin } from "@react-oauth/google";

export default function GoogleLoginBtn({ onSuccess, onError, className }) {
  return (
    <span className={className}>
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          onSuccess(credentialResponse);
        }}
        onError={(err) => {
          onError(err);
        }}
      />
    </span>
  );
}
