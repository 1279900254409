import { deleteRequest, getRequest, patchRequest, postRequest } from "./_setup";

export function getUserProfile() {
  return getRequest(`/users/profiles`);
}

export function updateProfEmail(body) {
  return patchRequest(`/users/profiles`, body);
}
export function updateDataSet(body) {
  return patchRequest(`users/datasets`, body);
}
export function fetchAllDataSets() {
  return getRequest(`/users/datasets`);
}
export function createDataSet(body) {
  return postRequest(`/users/datasets`, body);
}
export function fetchDataSet(params) {
  return getRequest(`/users/datasets`, params);
}
export function removeDataSet(path) {
  return deleteRequest(`/users/datasets/${path}`);
}
// export function download(body){
//   return postRequest(`/users/datasets`, body);
// }
