export const routes = {
  Login: "/login",
  Home: "/",
  NewRsume: "/resume/new",
  Dashboard: "/mydocuments",
  ResumeTemplates: "/resume/templates",
  CVTemplates: "/cv/templates",
  Templates: "/templates",
  Editor: "/resume",
  CVEditor: "/cv",
  Pricing: "/pricing",
  Faq: "/faq",
  Prep: "/prep",
  Datasets: "/datasets",
  ChannelPartnerLanding: "/channelpartner",
  ChannelPartnerBenefit: "/channelpartner/benefit",
  ChannelPartnerReg: "/channelpartner/registration",
  ChannelPartnerProfile: "/channelpartner/profile",
  PrivacyPolicy: "/privacy-policy",
  CookiePolicy: "/cookie-policy",
  TermsAndConditions: "/terms-and-conditions",
  PricingPolicy: "/pricing-policy",
  RefundPolicy: "/refund-policy",
  ContactUS: "/contact-us",
  ShippingPolicy: "/shipping-policy",
};
