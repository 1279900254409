import { message } from "antd";
import {
  postLoginGoogle,
  postLoginGoogleChannelPartner,
  postRefreshToken,
} from "api/auth";
import { getUserProfile } from "api/user";

import { routes } from "index/routes";
import {
  useReducer,
  createContext,
  useContext,
  useEffect,
  useState,
  // useEffect
} from "react";

import { getAuthToken, handleLogout, isLoggedIn } from "utils/helpers";
// import { getUserProfile } from "api/user";

export const AppContext = createContext();

const initialState = {
  isLoading: true,
  isLoaded: true,
  isOpenProfileUpdateModal: false,
  userProfile: {
    name: "",
  },
};

function reducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    case "SET_LOADING":
      return { ...state, isLoading: payload };
    case "SET_USER_PROFILE":
      return { ...state, userProfile: payload };
    case "SET_IS_OPEN_PROFILE_UPDATE_MODAL":
      return { ...state, isOpenProfileUpdateModal: payload };
    case "SET_IS_CLOSE_PROFILE_UPDATE_MODAL":
      return { ...state, isOpenProfileUpdateModal: payload };
    case "IS_LOADED":
      return { ...state, isLoaded: false };
    default:
      return state;
  }
}

// const refreshToken = async () => {
//   try {
//     if (
//       !localStorage.getItem["refreshToken"] ||
//       localStorage.getItem["refreshToken"] === "undefined"
//     ) {
//       console.error("No refresh token available");
//       handleLogout();
//     }

//     const { data } = await postRefreshToken(
//       localStorage.getItem["refreshToken"]
//     );

//     console.log(data);
//     window.localStorage.setItem("authToken", data.data.token);
//     window.localStorage.setItem("timestamp", Date.now());

//     window.location.reload();
//   } catch (e) {
//     console.error(e);
//   }
// };

// const hasTokenExpired = () => {
//   const accessToken = localStorage.getItem["authToken"];
//   const timestamp = localStorage.getItem["timestamp"];
//   const expireTime = 24 * 60 * 60 * 3;
//   if (!accessToken || !timestamp) {
//     return false;
//   }
//   const millisecondsElapsed = Date.now() - Number(timestamp);
//   return millisecondsElapsed / 1000 > expireTime;
// };

export function AppContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const ACCESS_TOKEN_EXPIRES_TIME = 10;

  function setLoading(isLoading) {
    dispatch({ type: "SET_LOADING", payload: isLoading });
  }
  function setIsOpenProfileUpdateModal(isOpen) {
    dispatch({ type: "SET_IS_OPEN_PROFILE_UPDATE_MODAL", payload: isOpen });
  }
  function setIsCloseProfileUpdateModal(isOpen) {
    handleLogout();
    dispatch({ type: "SET_IS_OPEN_PROFILE_UPDATE_MODAL", payload: isOpen });
  }

  function fetchUserProfile() {
    getUserProfile()
      .then((resp) => {
        dispatch({ type: "SET_USER_PROFILE", payload: resp.data.data });
        if (resp.data.data.isNew) {
          localStorage.setItem("role", resp.data.data.role);
          resp.data.data.role !== "channelpartner" &&
            setIsOpenProfileUpdateModal(true);
        } else {
          localStorage.setItem("role", resp.data.data.role);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function handleSuccessGoogleLogin(payload) {
    let resp;

    if (window.location.href.endsWith("/channelpartner")) {
      try {
        resp = await postLoginGoogleChannelPartner({
          token: payload.credential,
          // role: "channelpartner",
        });
        console.log(resp);
        localStorage.setItem("authToken", resp.data.data.token);
        if (resp.data.data.isNew) {
          // window.localStorage.setItem("timestamp", Date.now());
          if (window.location.href.endsWith("/channelpartner")) {
            window.location.href = routes.ChannelPartnerReg;
          } else {
            window.location.reload();
          }
        } else {
          localStorage.setItem("role", "channelpartner");

          // if (window.location.href.endsWith("/channelpartner")) {
          //   window.location.href = routes.ChannelPartnerReg;
          // } else {
          //   window.location.reload();
          // }
          window.location.href = routes.ChannelPartnerProfile;
        }
      } catch (error) {
        console.log(error);
        message.open({
          key: "login",
          type: "error",
          content: "The user already exist as a Student",
          duration: 2,
        });
      }
    } else {
      try {
        resp = await postLoginGoogle({ token: payload.credential });
        localStorage.setItem("authToken", resp.data.data.token);
        localStorage.setItem("refreshToken", resp.data.data.refreshToken);

        if (resp.data.data.isNew) {
          // window.localStorage.setItem("timestamp", Date.now());
          if (window.location.href.endsWith("/")) {
            window.location.href = routes.Dashboard;
          } else {
            window.location.reload();
          }
        } else {
          localStorage.setItem("role", "student");
          // if (
          //   hasTokenExpired() ||
          //   localStorage.getItem["authToken"] === "undefined"
          // ) {
          //   refreshToken();
          // }
          if (window.location.href.endsWith("/")) {
            window.location.href = routes.Dashboard;
          } else {
            window.location.reload();
          }
        }
      } catch (error) {
        message.open({
          key: "login",
          type: "error",
          content: "The user already exist as a Channel Partner",
          duration: 2,
        });
        console.log(error);
      }
    }
  }

  function refreshUserProfile() {
    fetchUserProfile();
  }

  useEffect(() => {
    isLoggedIn() && fetchUserProfile();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch({ type: "IS_LOADED" });
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  const value = {
    state,
    setLoading,
    handleSuccessGoogleLogin,
    setIsCloseProfileUpdateModal,
    setIsOpenProfileUpdateModal,
    refreshUserProfile,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export function useAppContext() {
  return useContext(AppContext);
}
