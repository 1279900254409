import hostnames from "./hostnames.json";
import hostnamesUrlMap from "./hostnamesUrlMap.json";

function removeWWWFromURL(url) {
  if (url.startsWith("www.")) return url.substring(4);
  return url;
}

const env = process.env.REACT_APP_ENV;
const HostnameApiUrlMap = {};
HostnameApiUrlMap[hostnames.PROD] = hostnamesUrlMap.PROD;
HostnameApiUrlMap[hostnames.DEV] = hostnamesUrlMap.DEV;
HostnameApiUrlMap[hostnames.LOCAL] = hostnamesUrlMap.LOCAL;

const { hostname } = window.location;

const hostnameWithoutWWW = removeWWWFromURL(hostname);

let apiBaseUrl = HostnameApiUrlMap[hostnameWithoutWWW];

/* For custom env */
if (env === "LOCAL") apiBaseUrl = "http://localhost:4001";
else if (env === "PROD") apiBaseUrl = hostnamesUrlMap.PROD;

const config = {
  env,
  apiBaseUrl,
  googleClientId:
    "106709358913-2fbf7gct5q6i96g3tr0u4cvs4noo55pb.apps.googleusercontent.com",
};

export default config;
