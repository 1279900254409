import { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import { routes } from "./routes";

import Preloader from "components-shared/Preloader/Preloader";

import { AnimatePresence } from "framer-motion";
import { useWithAuth } from "./useWithAuth";
import { Roles } from "utils/consts";
import useGlobalEffects from "./useGlobalEffect";

const Login = lazy(() => import("components/Login"));
const Landing = lazy(() => import("components/Landing"));
const CreateResume = lazy(() => import("components/CreateResume"));
const CoverLetterEditor = lazy(() => import("components/CoverLetterEditor"));
const Pricing = lazy(() => import("components/Pricing"));
const PageNotFound = lazy(() =>
  import("components-shared/ErrorBoundary/RouteError")
);
const Faq = lazy(() => import("components/FAQ"));
const PrepTips = lazy(() => import("components/PrepTips"));
const MyAccount = lazy(() => import("components/AllDataSets"));
const CVTemplate = lazy(() => import("components/CVTemplate"));
const Dashboard = lazy(() => import("components/Dashboard"));
const Editor = lazy(() => import("components/Editor/Editor"));
const ResumeTemplates = lazy(() =>
  import("components/ResumeTemplates/ResumeTemplates")
);
const AllTemplates = lazy(() => import("components/AllTemplates"));
const DataSet = lazy(() => import("components/DataSets/DataSet"));
const ChannelPartnerLanding = lazy(() =>
  import("components/ChannelPartnerLanding")
);
const ChannelPartnerBenefit = lazy(() =>
  import("components/ChannelPartnerBenefit")
);
const ChannelPartnerReg = lazy(() =>
  import("components/ChannelPartnerReg/ChannelPartnerReg")
);
const ChannelPartnerProfile = lazy(() =>
  import("components/ChannelPartnerReg/ChannelPartnerProfile")
);
const PrivacyPolicy = lazy(() => import("components/Policy/PrivacyPolicy"));
const CookiePolicy = lazy(() => import("components/Policy/CookiePolicy"));
const PircingPolicy = lazy(() => import("components/Policy/PricingPolicy"));
const RefundPolicy = lazy(() => import("components/Policy/RefundPolicy"));
const TermsAndConditions = lazy(() =>
  import("components/Policy/TermsAndConditions")
);

const ContactUs = lazy(() => import("components/Policy/ContactUS"));
const ShippingPolicy = lazy(() => import("components/Policy/ShippingPolicy"));
export default function AppRouter() {
  const { authorize } = useWithAuth();
  // useGlobalEffects();
  return (
    <Suspense fallback={<Preloader />}>
      <AnimatePresence mode="wait">
        <Routes>
          {/* element={authorize(<PublishedProblemSets />, [Roles.User])} */}
          {/* <Route path="/" element={<Navigate to={Routes.Home} />} /> */}
          <Route path={routes.Login} element={<Login />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path={routes.Home} element={<Landing />} />
          <Route
            path={routes.Dashboard}
            element={authorize(<Dashboard />, [Roles.User])}
          />
          <Route
            path={routes.NewRsume}
            element={authorize(<CreateResume />, [Roles.User])}
          />
          <Route path={routes.ResumeTemplates} element={<ResumeTemplates />} />
          <Route path={routes.Pricing} element={<Pricing />} />
          <Route
            path={`${routes.Editor}/:resumeId`}
            element={authorize(<Editor />, [Roles.User])}
          />
          <Route
            path={`${routes.CVEditor}/:CVId`}
            element={authorize(<CoverLetterEditor />, [Roles.User])}
          />
          <Route path={routes.Faq} element={<Faq />} />
          <Route path={routes.Prep} element={<PrepTips />} />
          <Route path={routes.CVTemplates} element={<CVTemplate />} />
          <Route
            path={routes.Datasets}
            element={authorize(<MyAccount />, [Roles.User])}
          />
          <Route
            path={`${routes.Datasets}/:datasetId`}
            element={authorize(<DataSet />, [Roles.User])}
          />
          <Route path={routes.Templates} element={<AllTemplates />} />
          {/* <Route path="dsf" element={<ResumeTemplate />} /> */}
          <Route
            path={routes.ChannelPartnerLanding}
            element={<ChannelPartnerLanding />}
          />
          <Route
            path={routes.ChannelPartnerBenefit}
            element={<ChannelPartnerBenefit />}
          />
          <Route
            path={routes.ChannelPartnerReg}
            element={<ChannelPartnerReg />}
          />
          <Route
            path={routes.ChannelPartnerProfile}
            element={<ChannelPartnerProfile />}
          />
          <Route path={routes.PrivacyPolicy} element={<PrivacyPolicy />} />
          <Route path={routes.CookiePolicy} element={<CookiePolicy />} />
          <Route
            path={routes.TermsAndConditions}
            element={<TermsAndConditions />}
          />
          <Route
            path={routes.TermsAndConditions}
            element={<TermsAndConditions />}
          />
          <Route path={routes.PricingPolicy} element={<PircingPolicy />} />
          <Route path={routes.RefundPolicy} element={<RefundPolicy />} />
          <Route path={routes.ContactUS} element={<ContactUs />} />
          <Route path={routes.ShippingPolicy} element={<ShippingPolicy />} />
        </Routes>
      </AnimatePresence>
    </Suspense>
  );
}
