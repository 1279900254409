import "./index.scss";
import "react-toastify/dist/ReactToastify.min.css";

import { BrowserRouter, useLocation } from "react-router-dom";

import { ErrorBoundary } from "react-error-boundary";
import Navbar from "components-layout/Navbar";

import { AppContextProvider } from "contexts/AppContext";
import Footer from "components-layout/Footer/Footer";

import AppRouter from "./AppRouter";

import { GoogleOAuthProvider } from "@react-oauth/google";
import config from "config/config";

import { motion } from "framer-motion";
import clsx from "clsx";
import Error from "components-shared/ErrorBoundary/Errorboundary";
import useGlobalEffects from "./useGlobalEffect";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

function AppWrapper() {
  const location = useLocation();

  const pattern = /^\/resume\/[0-9a-f]{24}$/;
  useGlobalEffects();
  return (
    <>
      <GoogleOAuthProvider clientId={config.googleClientId}>
        <Navbar />

        <div className={"mainWrapper min-h-[60vh]"}>
          <AppRouter />
        </div>

        {!pattern.test(location.pathname) && <Footer />}
      </GoogleOAuthProvider>
    </>
  );
}

function App() {
  return (
    <ErrorBoundary fallback={<Error />}>
      <motion.div
        animate={{ y: 0, height: 0 }}
        transition={{ ease: "easeOut", duration: 1 }}
        className={clsx("absolute h-screen w-screen bg-white z-[1]")}
      ></motion.div>
      <AppContextProvider>
        <BrowserRouter>
          <AppWrapper />
        </BrowserRouter>
      </AppContextProvider>
    </ErrorBoundary>
  );
}

export default App;
