import React from "react";

import clsx from "clsx";
import ErrorBoundary from "assets/images/errorBoundary.svg";
import { handleLogout } from "utils/helpers";
import Button from "components-shared/Button/Button";

export default function Error() {
  return (
    <div className="flex flex-col justify-center items-center gap-4 mt-8">
      <img className="w-[25em]" src={ErrorBoundary} alt="Error" />
      <span>Something Went Wrong</span>
      <Button onClick={handleLogout}>Return To Home Page</Button>
    </div>
  );
}
