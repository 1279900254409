import clsx from "clsx";
import { Input as AntInput } from "antd";

export default function Input({
  label,
  placeholder,
  className,
  onChange,
  ...props
}) {
  return (
    <div className={`${className}`} aria-disabled={props.disabled}>
      <label htmlFor={props.name} className="font-medium">
        {label}
      </label>
      <AntInput
        placeholder={placeholder}
        variant="filled"
        onChange={onChange}
        size="large"
        {...props}
      />
    </div>
  );
}
