import { AiOutlineLogout as LogOut } from "react-icons/ai";
import { FiUser } from "react-icons/fi";
import { FaRegCircleQuestion } from "react-icons/fa6";
import { MdOutlineTipsAndUpdates } from "react-icons/md";
import { IoDocumentOutline } from "react-icons/io5";
import { LuFileSpreadsheet } from "react-icons/lu";

import CompanyLogo1 from "assets/Marquee/sector1.png";
import CompanyLogo2 from "assets/Marquee/sector2.png";
import CompanyLogo3 from "assets/Marquee/sector3.png";
import CompanyLogo4 from "assets/Marquee/sector4.png";
import CompanyLogo5 from "assets/Marquee/sector5.png";
import CompanyLogo6 from "assets/Marquee/sector6.png";
import CompanyLogo7 from "assets/Marquee/sector7.png";
import CompanyLogo8 from "assets/Marquee/sector8.png";

import CompanyLogo9 from "assets/Marquee/sector9.png";


// marquee
export const logos = [
  { id: 1, src: CompanyLogo1, alt: "Sector1" },
  { id: 2, src: CompanyLogo2, alt: "Sector2" },
  { id: 3, src: CompanyLogo3, alt: "Sector3" },
  { id: 4, src: CompanyLogo4, alt: "Sector4" },
  { id: 5, src: CompanyLogo5, alt: "Sector5" },
  { id: 6, src: CompanyLogo6, alt: "Sector6" },
  { id: 7, src: CompanyLogo7, alt: "Sector7" },
  { id: 8, src: CompanyLogo8, alt: "Sector8" },
  { id: 9, src: CompanyLogo9, alt: "Sector9" },
];

export const Roles = {
  User: "student",
  Admin: "admin",
};

export const NavLinks = [
  // { label: "Dashboard", link: "/dashboard", icon: <FiUser /> },
  { label: "My Documents", link: "", icon: <IoDocumentOutline /> },
  { label: "Templates", link: "/templates", icon: <LuFileSpreadsheet /> },
  { label: "Help", link: "/faq", icon: <FaRegCircleQuestion /> },
  // { label: "PrepTips", link: "/prep", icon: <MdOutlineTipsAndUpdates /> },
  { label: "Logout", link: "", icon: <LogOut /> },
];
